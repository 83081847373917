import { isObject, mergeWith } from 'lodash-es';

export function mergeObjects(a: any, b: any) {
    return mergeWith({}, a, b, (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
            return srcValue;
        } else if (isObject(objValue) && isObject(srcValue)) {
            return mergeObjects(objValue, srcValue);
        } else if (srcValue === null || srcValue === undefined) {
            return objValue;
        }
        return srcValue;
    });
}
