/* eslint-disable */
// https://dev2.bhsn.it/api/mtm/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '../../utils';

let axiosInstance: AxiosInstance;

const serviceRoute = 'mtm';
export function setupServiceInstance(callback: (serviceRoute: string) => AxiosInstance) {
    axiosInstance = callback(serviceRoute);
}

/**
 * @returns string OK
 * @throws ApiError
 */
export function processPaymentResult(
    {
        returnUrl,
        paymentResult,
    }: {
        returnUrl: string;
        paymentResult: MtmNicePayPaymentResult;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/payments/nicepay/callback/v1',
                query: {
                    returnUrl: returnUrl,
                    paymentResult: paymentResult,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 부가 정보 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateWorkspaceAdditionalInfo(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceAdditionalInfoForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/workspaces/current/additional-info/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 조회
 * @returns MtmLissResponseUserProfileDto OK
 * @throws ApiError
 */
export function getUser(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserProfileDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 수정
 * @returns MtmLissResponseUserUpdatedDto OK
 * @throws ApiError
 */
export function updatedUser(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserUpdatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 목록 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getUserRoles(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/roles/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserRoles(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRolesUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/roles/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserRole(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRoleUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/role/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 로케일 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserPreference(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserPreferenceForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/preference/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 소속 그룹(GROUP) 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUserGroup(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserGroupForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/groups/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자에 소속 그룹 할당
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function assignGroupsToUser(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserGroupForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/users/{userId}/groups/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 게스트를 구성원으로 변경
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function convertGuestToMember(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRolesUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/convert-guest/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 인사 정보 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUserAffiliations(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserAffiliationUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/affiliations/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격 정보 강제 초기화
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function forceResetUserCredential(
    {
        requestBody,
    }: {
        requestBody: MtmUserCredentialForceResetForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admins/user-credentials/force/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 조회
 * @returns MtmLissResponseRolePermissionDto OK
 * @throws ApiError
 */
export function getRole(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseRolePermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 수정
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function updateRole(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRoleUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteRole(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할별 설정 가능한 퍼미션 목록 조회
 * @returns MtmLissResponseListPermissionCategoryDto OK
 * @throws ApiError
 */
export function getPermissions(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListPermissionCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updatePermission(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 추가
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addPermissions(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deletePermission(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 조회
 * @returns MtmLissResponsePermissionDto OK
 * @throws ApiError
 */
export function getPermission(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 수정
 * @returns MtmLissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function updatePermission1(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmPermissionUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePermissionCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deletePermission1(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 상세 조회
 * @returns MtmLissResponseNoticeDto OK
 * @throws ApiError
 */
export function getNotice(
    {
        noticeId,
    }: {
        noticeId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseNoticeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateNotice(
    {
        noticeId,
        requestBody,
    }: {
        noticeId: number;
        requestBody: MtmNoticeUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteNotice(
    {
        noticeId,
    }: {
        noticeId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobTitle(
    {
        jobTitleId,
        requestBody,
    }: {
        jobTitleId: number;
        requestBody: MtmJobTitleUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-titles/{jobTitleId}/v1',
                path: {
                    jobTitleId: jobTitleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteJobTitle(
    {
        jobTitleId,
    }: {
        jobTitleId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/job-titles/{jobTitleId}/v1',
                path: {
                    jobTitleId: jobTitleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 순서 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobTitlesDisplayOrder(
    {
        requestBody,
    }: {
        requestBody: MtmUpdateDisplayOrderForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-titles/display-order/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 수정
 * @returns MtmLissResponseJobPositionDto OK
 * @throws ApiError
 */
export function updateJobPosition(
    {
        jobPositionId,
        requestBody,
    }: {
        jobPositionId: number;
        requestBody: MtmJobPositionUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-positions/{jobPositionId}/v1',
                path: {
                    jobPositionId: jobPositionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteJobPosition(
    {
        jobPositionId,
    }: {
        jobPositionId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/job-positions/{jobPositionId}/v1',
                path: {
                    jobPositionId: jobPositionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 순서 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobPositionsDisplayOrder(
    {
        requestBody,
    }: {
        requestBody: MtmUpdateDisplayOrderForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-positions/display-order/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 조회
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function getGroup(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 수정
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function updateGroup(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteGroup(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function getGroupUsers(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/users/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 사용자 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateGroupUsers(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupUserForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/groups/{groupId}/users/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조직도 조회
 * @returns MtmLissResponseListDivisionNodeDto OK
 * @throws ApiError
 */
export function getDivisionTree(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListDivisionNodeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/tree/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조직도 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateDivisionTree(
    {
        requestBody,
    }: {
        requestBody: MtmDivisionNodesForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/divisions/tree/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 v2 (단일 역할)
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function inviteUser(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 목록 조회
 * @returns MtmLissResponsePageUserInvitationOverviewDto OK
 * @throws ApiError
 */
export function pageInvitations(
    {
        groupId,
        loginId,
        expired,
        jobPositionIds,
        statuses,
        page,
        size = 30,
        sort,
    }: {
        groupId?: number;
        loginId?: string;
        expired?: boolean;
        jobPositionIds?: Array<number>;
        statuses?: Array<'PENDING' | 'SENT' | 'ACCEPTED' | 'CANCELLED' | 'EXPIRED' | 'FAILED'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageUserInvitationOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/v1',
                query: {
                    groupId: groupId,
                    loginId: loginId,
                    expired: expired,
                    jobPositionIds: jobPositionIds,
                    statuses: statuses,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 사용자 초대
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function inviteUser1(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 재발송
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function resendInvitations(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationResendForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/resend/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 동의
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function consentTermRevisions(
    {
        requestBody,
    }: {
        requestBody: MtmTermRevisionConsentForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/terms/revisions/consent/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 엑셀로 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsersByExcel(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
        };
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/users/excel/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsersV2(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminUserCreateFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/user/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼어드민 사용자 검색 API
 * @returns MtmLissResponsePageSuperAdminUserOverviewVo OK
 * @throws ApiError
 */
export function pageUsers1(
    {
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 검색어
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageSuperAdminUserOverviewVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admins/user/v1',
                query: {
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsers(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminUserCreateFormV1;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/user/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 회원가입 - 계정 생성
 * @returns MtmLissResponseUserCreatedDto OK
 * @throws ApiError
 */
export function createUser(
    {
        requestBody,
    }: {
        requestBody: MtmUserCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/sign-up/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 회원가입 - 워크스페이스 추가
 * @returns MtmLissResponseUserWorkspaceCreatedDto OK
 * @throws ApiError
 */
export function createUserWorkspace(
    {
        requestBody,
    }: {
        requestBody: MtmUserWorkspaceCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserWorkspaceCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/sign-up/user-workspaces/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 생성 v2
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function createRole(
    {
        requestBody,
    }: {
        requestBody: MtmRoleCreateFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 목록 조회 - 제품별
 * @returns MtmLissResponseListProductWithRoleDto OK
 * @throws ApiError
 */
export function listRoles(
    {
        type,
    }: {
        type?: 'GUEST' | 'USER';
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListProductWithRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 역할 생성
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function createRole1(
    {
        requestBody,
    }: {
        requestBody: MtmRoleCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 목록 조회
 * @returns MtmLissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageUserRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/users/v1',
                query: {
                    statuses: statuses,
                    requesterIds: requesterIds,
                    roleIds: roleIds,
                    approveUserId: approveUserId,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 추가
 * @returns MtmLissResponseListUserRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmUserRoleRequestCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListUserRoleRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 거부
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function rejectUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/rejected/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 승인
 * @returns MtmLissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListApprovedRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/approved/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 목록 조회
 * @returns MtmLissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageGroupRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/groups/v1',
                query: {
                    statuses: statuses,
                    requesterIds: requesterIds,
                    roleIds: roleIds,
                    approveUserId: approveUserId,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 추가
 * @returns MtmLissResponseListGroupRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmGroupRoleRequestCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListGroupRoleRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 거부
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function rejectGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/rejected/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 승인
 * @returns MtmLissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListApprovedRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/approved/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 활성 퍼미션 리스트 조회 (구독 제품 기준)
 * @returns MtmLissResponseListPermissionDto OK
 * @throws ApiError
 */
export function listPermissions(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListPermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 생성
 * @returns MtmLissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function createPermission(
    {
        requestBody,
    }: {
        requestBody: MtmPermissionCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePermissionCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/permissions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 발급
 * @returns MtmLissResponseOrderDto OK
 * @throws ApiError
 */
export function publishOrder(
    {
        requestBody,
    }: {
        requestBody: MtmOrderPublishForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseOrderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createNotice(
    {
        requestBody,
    }: {
        requestBody: MtmNoticeCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/notices/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 벌크 생성 API (직책 코드 포함)
 * @returns MtmLissResponseListJobTitleDto OK
 * @throws ApiError
 */
export function createJobTitles(
    {
        requestBody,
    }: {
        requestBody: Array<MtmJobTitleCreateForm>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListJobTitleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-titles/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 모든 직책 조회
 * @returns MtmLissResponseListJobTitleDto OK
 * @throws ApiError
 */
export function getJobTitles(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListJobTitleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/job-titles/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 추가 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addJobTitles(
    {
        requestBody,
    }: {
        requestBody: MtmJobTitleAddForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-titles/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 벌크 추가 v2
 * @returns MtmLissResponseListJobPositionDto OK
 * @throws ApiError
 */
export function createJobPositionV2(
    {
        requestBody,
    }: {
        requestBody: Array<MtmJobPositionCreateForm>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-positions/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 리스트 조회
 * @returns MtmLissResponseListJobPositionDto OK
 * @throws ApiError
 */
export function getJobPosition(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/job-positions/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 벌크 추가
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createJobPosition(
    {
        requestBody,
    }: {
        requestBody: MtmJobPositionAddForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-positions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 목록 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getGroupRoles(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupRoleForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 벌크 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupRoleForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 목록 조회
 * @returns MtmLissResponsePageGroupDto OK
 * @throws ApiError
 */
export function pageGroups(
    {
        parentId,
        name,
        type,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 부모 그룹 ID
         */
        parentId?: number;
        /**
         * 그룹명
         */
        name?: string;
        /**
         * 그룹 타입
         */
        type?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/v1',
                query: {
                    parentId: parentId,
                    name: name,
                    type: type,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 생성
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function createGroup(
    {
        requestBody,
    }: {
        requestBody: MtmGroupCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/groups/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function sendMail(
    {
        requestBody,
    }: {
        requestBody: MtmExternalMailForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/external/mails/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 생성
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function createDivision(
    {
        requestBody,
    }: {
        requestBody: MtmDivisionCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/divisions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 단건 조회
 * @returns MtmLissResponseWorkspaceDto OK
 * @throws ApiError
 */
export function getWorkspace(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 정보 조회
 * @returns MtmLissResponseWorkspaceDto OK
 * @throws ApiError
 */
export function getCurrentWorkspace(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 상세 정보 조회
 * @returns MtmLissResponseWorkspaceDetailInfoVo OK
 * @throws ApiError
 */
export function getCurrentWorkspaceDetail(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseWorkspaceDetailInfoVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/current/detail/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 조회(overview)
 * @returns MtmLissResponseUserOverviewDto OK
 * @throws ApiError
 */
export function getUserOverview(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/overview/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 페이징 조회(어드민)
 * @returns MtmLissResponsePageUserDto OK
 * @throws ApiError
 */
export function pageUsers(
    {
        keyword,
        page,
        size = 30,
        sort,
        external,
    }: {
        keyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        external?: boolean;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/v1',
                query: {
                    keyword: keyword,
                    page: page,
                    size: size,
                    sort: sort,
                    external: external,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 의뢰 결재자 조회
 * @returns MtmLissResponseListUserSimpleAffiliationDto OK
 * @throws ApiError
 */
export function listReviewApprovers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserSimpleAffiliationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/review-approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 페이징 조회(overview)
 * @returns MtmLissResponsePageUserOverviewDto OK
 * @throws ApiError
 */
export function pageUserOverviews(
    {
        searchKeyword,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/overview/v1',
                query: {
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 송무 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLitigationReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/litigation-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 법무 담당자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLegalReviewers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/legal-reviewers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 법무 배정자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLegalAssigners(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/legal-assigners/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회 (소속 부서 내 계약)
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listGroupContractReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/group-contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 송무 COMPLETE 권한 가진 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLitigationOwners(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-litigation-completes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 법무 검토자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLegalReviewers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-legal-reviewers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 법무 검토승인자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLegalApprovers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-legal-approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 외부 변호사 권한 가진 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmExternalLawyers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-external-lawyers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listContractReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 자문 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listConsultingReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/consulting-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 화면에서 사용하는 코드 조회
 * @returns MtmLissResponseMapStringListObject OK
 * @throws ApiError
 */
export function getUserCodes(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listUsersByRole(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/by-role/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회 (배정 계약)
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listAssignedContractReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/assigned-contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 할당 전자결재 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listAssignedApprovalReaders(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/assigned-approval-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listUsers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/all/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 조회
 * @returns MtmLissResponseUserInvitationDto OK
 * @throws ApiError
 */
export function getInvitationByToken(
    {
        token,
    }: {
        token: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseUserInvitationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/invitation/v1',
                query: {
                    token: token,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 기가입자 여부 확인
 * @returns MtmLissResponseBoolean OK
 * @throws ApiError
 */
export function checkUserExists(
    {
        loginId,
    }: {
        loginId: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseBoolean> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/check-user-exists/v1',
                query: {
                    loginId: loginId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadTermRevision(
    {
        termId,
        revisionId,
    }: {
        termId: number;
        revisionId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/{termId}/revisions/{revisionId}/download/v1',
                path: {
                    termId: termId,
                    revisionId: revisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리비전 리스트 조회
 * @returns MtmLissResponseListTermRevisionOverviewDto OK
 * @throws ApiError
 */
export function listRevisionOverviews(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListTermRevisionOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/{id}/revisions/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 발효중인 약관 리스트 조회
 * @returns MtmLissResponseListTermSimpleDto OK
 * @throws ApiError
 */
export function listTermsActiveAndEffective(
    {
        type,
    }: {
        /**
         * 약관 유형
         */
        type?: string;
    } = {},
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListTermSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 미동의 약관 리스트 조회
 * @returns MtmLissResponseListTermSimpleDto OK
 * @throws ApiError
 */
export function getUnConsentedTerms(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListTermSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/un-consented/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 목록 조회
 * @returns MtmLissResponsePageSuperAdminVo OK
 * @throws ApiError
 */
export function listSuperAdmins(
    {
        page,
        size = 20,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageSuperAdminVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admins/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 구독 정보 조회
 * @returns MtmLissResponseSubscriptionInfoDto OK
 * @throws ApiError
 */
export function getCurrentSubscription(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseSubscriptionInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/subscriptions/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 목록 조회 - 사용자 수 포함
 * @returns MtmLissResponseListRoleWithUserCountDto OK
 * @throws ApiError
 */
export function getRolesWithUserCounts(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListRoleWithUserCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/user-count/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 승인 가능 권한자 목록 조회
 * @returns MtmLissResponseListUserIdentityDto OK
 * @throws ApiError
 */
export function getApproveUsers(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserIdentityDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 활성 제품 목록 조회
 * @returns MtmLissResponseListProductDto OK
 * @throws ApiError
 */
export function getProducts(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListProductDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/products/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 가능 플랜 및 요금제 조회
 * @returns MtmLissResponseListPlanPricesDto OK
 * @throws ApiError
 */
export function listRegularPlanPrices(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListPlanPricesDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/plans/prices/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 상태 조회
 * @returns MtmLissResponseOrderStatusType OK
 * @throws ApiError
 */
export function getOrder(
    {
        orderRequestId,
    }: {
        orderRequestId: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseOrderStatusType> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/{orderRequestId}/status/v1',
                path: {
                    orderRequestId: orderRequestId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 이력 조회
 * @returns MtmLissResponsePageOrderHistoryDto OK
 * @throws ApiError
 */
export function getOrderHistories(
    {
        pageable,
    }: {
        pageable: MtmPageable;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageOrderHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/histories/v1',
                query: {
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadFile(
    {
        noticeId,
        fileKey,
    }: {
        noticeId: number;
        /**
         * 파일 Key
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/{noticeId}/download/v1',
                path: {
                    noticeId: noticeId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 검색
 * @returns MtmLissResponsePageNoticeVo OK
 * @throws ApiError
 */
export function searchNotice(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        noticeTypes,
        creatorIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 공지 사항 구분
         */
        noticeTypes?: Array<'UNSPECIFIED' | 'CONTRACT' | 'LITIGATION' | 'CONSULTING'>;
        /**
         * 공지 사항 작성자 목록
         */
        creatorIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageNoticeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    noticeTypes: noticeTypes,
                    creatorIds: creatorIds,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 작성자 목록 조회
 * @returns MtmLissResponseListUserProfileSimpleVo OK
 * @throws ApiError
 */
export function getCreators(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListUserProfileSimpleVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/creators/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 화면에서 사용하는 코드 조회
 * @returns MtmLissResponseMapStringListObject OK
 * @throws ApiError
 */
export function getNoticeCodes(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 소속 워크스페이스 리스트 조회
 * @returns MtmLissResponseListMyWorkspaceDto OK
 * @throws ApiError
 */
export function getMyWorkspaces(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListMyWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/workspaces/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 워크스페이스 정보 조회
 * @returns MtmLissResponseMyWorkspaceInfoDto OK
 * @throws ApiError
 */
export function getMyWorkspaceInfo(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseMyWorkspaceInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/workspace/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 정보 조회
 * @returns MtmLissResponseMyInfoDto OK
 * @throws ApiError
 */
export function getMyInfo(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseMyInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 역할 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getMyRoles(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/roles/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 조회
 * @returns MtmLissResponseListGroupDto OK
 * @throws ApiError
 */
export function getMyGroups(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/groups/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 워크스페이스내의 소속 정보 조회
 * @returns MtmLissResponseMyAffiliationDto OK
 * @throws ApiError
 */
export function getMyAffiliation(
    {
        workspaceId,
    }: {
        workspaceId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseMyAffiliationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/affiliation/{workspaceId}/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 런처 조회 v2
 * @returns MtmLissResponseListLauncherDtoV2 OK
 * @throws ApiError
 */
export function getLauncherV2(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListLauncherDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/launchers/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 런처 조회
 * @returns MtmLissResponseListLauncherDto OK
 * @throws ApiError
 */
export function getLauncher(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListLauncherDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/launchers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 상세 조회
 * @returns MtmLissResponseDivisionDto OK
 * @throws ApiError
 */
export function getDivision(
    {
        divisionId,
    }: {
        divisionId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseDivisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/{divisionId}/v1',
                path: {
                    divisionId: divisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 내 사용자 조회 (소속 정보 포함)
 * @returns MtmLissResponseDivisionMembersDto OK
 * @throws ApiError
 */
export function getDivisionUsers(
    {
        divisionId,
    }: {
        divisionId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseDivisionMembersDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/{divisionId}/users/v1',
                path: {
                    divisionId: divisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteUser(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/users/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 취소
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function cancelInvitation(
    {
        invitationId,
    }: {
        invitationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/user-invitations/{invitationId}/v1',
                path: {
                    invitationId: invitationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 삭제 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteUser1(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admins/user/{userId}/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 상세 조회
 * @returns MtmLissResponseWorkspaceDetailsDto OK
 * @throws ApiError
 */
export function getWorkspaceSpecification(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseWorkspaceDetailsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateWorkspace(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmWorkspaceCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspaces/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 상세 조회
 * @returns MtmLissResponseTermDto OK
 * @throws ApiError
 */
export function getTerm(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseTermDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateTerm(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmTermUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteTerm(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 페이지 조회 (overview)
 * @returns MtmLissResponsePageWorkspaceOverviewDto OK
 * @throws ApiError
 */
export function pageWorkspaceOverviews(
    {
        page,
        size = 10,
        sort,
        name,
        subscriptionStatus,
        planType,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        /**
         * 워크스페이스 명
         */
        name?: string;
        /**
         * 구독 상태
         */
        subscriptionStatus?: string;
        /**
         * 플랜 종류
         */
        planType?: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageWorkspaceOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                    name: name,
                    subscriptionStatus: subscriptionStatus,
                    planType: planType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createWorkspace(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/workspaces/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 신규 리비전 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermRevision(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmTermRevisionCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/{id}/revisions/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermV2(
    {
        requestBody,
    }: {
        requestBody: MtmTermCreateFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리스트 조회
 * @returns MtmLissResponseListTermOverviewDto OK
 * @throws ApiError
 */
export function listTerms(
    {
        type,
    }: {
        /**
         * 약관 유형
         */
        type?: 'SERVICE' | 'PAYMENT';
    } = {},
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseListTermOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 약관 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTerm(
    {
        requestBody,
    }: {
        requestBody: MtmTermCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 부서/직위/직책/권한 조회
 * @returns MtmLissResponseWorkspaceAffiliationsDto OK
 * @throws ApiError
 */
export function getWorkspaceAffiliations(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponseWorkspaceAffiliationsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/{id}/affiliations/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 검색 (simple)
 * @returns MtmLissResponsePageWorkspaceSimpleDto OK
 * @throws ApiError
 */
export function searchSimpleWorkspace(
    {
        name,
        subscriptionStatus,
        planType,
        page,
        size = 100,
        sort,
    }: {
        /**
         * 워크스페이스 명
         */
        name?: string;
        /**
         * 구독 상태
         */
        subscriptionStatus?: string;
        /**
         * 플랜 종류
         */
        planType?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponsePageWorkspaceSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/simple-search/v1',
                query: {
                    name: name,
                    subscriptionStatus: subscriptionStatus,
                    planType: planType,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadTermRevisionFile(
    {
        termId,
        revisionId,
    }: {
        termId: number;
        revisionId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/{termId}/revisions/{revisionId}/download/v1',
                path: {
                    termId: termId,
                    revisionId: revisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 리스트 조회
 * @returns MtmLissResponseListPlanDto OK
 * @throws ApiError
 */
export function listPlans(requestConfig: RequestConfig = {}): AxiosPromise<MtmLissResponseListPlanDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/plans/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리비전 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteTermRevision(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/terms/revisions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
