import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';
import { serializeParams } from './';

export function setupDefault(instance: AxiosInstance) {
    instance.defaults.paramsSerializer = function (paramObj: Record<string, any>) {
        // axios 에서 query string을 만들 때, 배열이 값으로 들어가면, 'arr[]=1&arr[]=2' 이런식으로 만들어지는데, 이걸 'arr=1&arr=2' 이런식으로 만들어주는 serializer
        // 추가적으로, object가 값으로 들어가면, 'obj=[object Object]' 이런식으로 만들어지는데, 이걸 'obj.a=b&obj.c=d' 이런식으로 만들어주는 serializer
        // object일 경우, 1차 object는 flatten되어 prefix가 붙지 않도록 무시됨.
        // ex) { a: 'b', c: { d: 'e' } } => 'a=b&d=e'
        return serializeParams(paramObj);
    };
}

// baseURL이 없는 axios instance를 만드는 함수
export function createAxiosInstance(config?: CreateAxiosDefaults) {
    const instance = axios.create(config);

    setupDefault(instance);
    return instance;
}
