import { createAxiosInstance } from '../utils';

const axiosInstance = createAxiosInstance();
// 단건 데이터 조회
export const getPost = async (params: { id: number | string } = { id: 1 }, options = {}) => {
    const res = await axiosInstance.get(`https://jsonplaceholder.typicode.com/posts/${params.id}`, {
        params,
        ...options,
    });
    return res;
};

// 리스트 데이터 조회
export const getPosts = async (params: { page: number | string } = { page: 1 }, options = {}) => {
    const res = await axiosInstance.get('https://jsonplaceholder.typicode.com/posts', {
        params,
        ...options,
    });
    return res;
};
